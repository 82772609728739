import moment from 'moment'
import { ServiceFactory } from '@/services/ServiceFactory'
const _discountCalendarService = ServiceFactory.get('DiscountCalendarService')

export default {
  data () {
    return {
      dialog: false,
      date: '',
      day: '',
      weekday: '',
      menu2: false,
      submitType: 'create',
      loading: false,
      loadingTable: false,
      eventId: '',
      barId: '',
      postData: {
        zbBarId: '',
        name: '',
        description: '',
        date: '',
        maxPeople: 0,
        schedule: [
          // {
          //   day: 0,
          //   hour: '',
          //   discount: 0,
          //   people: 0
          // }
          // {
          //   '08:00': {text, value},
          //   '08:30': null
          // }
          // hours['08:00']
        ]
      },
      discounts: [
        // {
        //   text: 'Ninguno',
        //   value: ''
        // },
        {
          text: '10 %',
          value: 0.1
        },
        {
          text: '15 %',
          value: 0.15
        },
        {
          text: '20 %',
          value: 0.2
        },
        {
          text: '25 %',
          value: 0.25
        },
        {
          text: '30 %',
          value: 0.3
        },
        {
          text: '35 %',
          value: 0.35
        },
        {
          text: '40 %',
          value: 0.4
        },
        {
          text: '45 %',
          value: 0.45
        },
        {
          text: '50 %',
          value: 0.5
        }
      ],
      hours: {
        '08:00': null,
        '08:30': null,
        '09:00': null,
        '09:30': null,
        '10:00': null,
        '10:30': null,
        '11:00': null,
        '11:30': null,
        '12:00': null,
        '12:30': null,
        '13:00': null,
        '13:30': null,
        '14:00': null,
        '14:30': null,
        '15:00': null,
        '15:30': null,
        '16:00': null,
        '16:30': null,
        '17:00': null,
        '17:30': null,
        '18:00': null,
        '18:30': null,
        '19:00': null,
        '19:30': null,
        '20:00': null,
        '20:30': null,
        '21:00': null,
        '21:30': null,
        '22:00': null,
        '22:30': null,
        '23:00': null,
        '23:30': null,
        '00:00': null
      },
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Descripción', value: 'description' },
        { text: 'Fecha', value: 'date' },
        // { text: 'Descuento', value: 'discount' },
        { text: 'Opciones', value: 'options' }
      ],
      events: [
        // {
        //   name: 'Halloween',
        //   description: 159,
        //   date: 6.0,
        //   protein: 4.0,
        //   discount: '1%'
        // },
        // {
        //   name: 'Primavera',
        //   description: 237,
        //   date: 6.0,
        //   protein: 4.3,
        //   discount: '1%'
        // },
        // {
        //   name: 'Navidad',
        //   description: 262,
        //   date: 6.0,
        //   protein: 6.0,
        //   discount: '7%'
        // },
        // {
        //   name: 'Carnaval',
        //   description: 305,
        //   date: 6.0,
        //   protein: 4.3,
        //   discount: '8%'
        // },
        // {
        //   name: 'Aniversario',
        //   description: 356,
        //   date: 6.0,
        //   protein: 3.9,
        //   discount: '16%'
        // }
      ]
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        this.barId = this.$route.params.id
        this.postData.zbBarId = this.barId
        try {
          var discResp = await _discountCalendarService.show(this.barId)
          this.events = discResp.events
          console.log(discResp)
        } catch (error) {
          console.log(error)
        }
      } else {
        this.$router.push({ name: 'companies' })
      }
    },
    getDay () {
      console.log('day')
      moment.locale('es')
      this.weekday = moment(this.postData.date).weekday()
      // console.log(day, moment().weekday(0))
      switch (this.weekday) {
        case 0:
          this.day = 'Lunes'
          // this.weekday = 0
          break
        case 1:
          this.day = 'Martes'
          // this.weekday = 1
          break
        case 2:
          this.day = 'Miércoles'
          // this.weekday = 2
          break
        case 3:
          this.day = 'Jueves'
          // this.weekday = 3
          break
        case 4:
          this.day = 'Viernes'
          // this.weekday = 4
          break
        case 5:
          this.day = 'Sábado'
          // this.weekday = 5
          break
        case 6:
          this.day = 'Domingo'
          // this.weekday = 6
          break
        default:
          break
      }
      console.log(this.day, this.weekday)
    },
    addDiscount (hour, value) {
      console.log(hour)
      console.log(value)
      // if (value !== '') {
      this.postData.schedule.push(
        {
          day: this.weekday,
          hour: hour,
          discount: value,
          people: parseInt(this.postData.maxPeople)
        }
      )
      // } else {
      //   console.log(hourDis)
      // }
    },
    removeDiscount (hour) {
      console.log(hour)
      this.hours[hour] = null
      // this.postData.schedule.map((el, i) => {
      //   if (el.hour === hour) {
      //     console.log(i)
      //     this.postData.schedule.splice(i, 1)
      //     // return i
      //   }
      // })
    },
    showEvent (item) {
      console.log(item)
      this.submitType = 'edit'
      this.eventId = item.id
      this.postData = {
        zbBarId: item.business.id,
        name: item.name,
        description: item.description,
        date: item.date,
        maxPeople: item.maxPeople,
        schedule: item.meta
      }
      item.meta.forEach(el => {
        this.hours[el.hour] = el.discount
      })
      this.getDay()
      this.dialog = true
    },
    clearForm () {
      this.dialog = false
      this.eventId = ''
      this.postData = {
        zbBarId: this.barId,
        name: '',
        description: '',
        date: '',
        maxPeople: 0,
        schedule: []
      }
      this.hours = {
        '08:00': null,
        '08:30': null,
        '09:00': null,
        '09:30': null,
        '10:00': null,
        '10:30': null,
        '11:00': null,
        '11:30': null,
        '12:00': null,
        '12:30': null,
        '13:00': null,
        '13:30': null,
        '14:00': null,
        '14:30': null,
        '15:00': null,
        '15:30': null,
        '16:00': null,
        '16:30': null,
        '17:00': null,
        '17:30': null,
        '18:00': null,
        '18:30': null,
        '19:00': null,
        '19:30': null,
        '20:00': null,
        '20:30': null,
        '21:00': null,
        '21:30': null,
        '22:00': null,
        '22:30': null,
        '23:00': null,
        '23:30': null,
        '00:00': null
      }
    },
    async submit () {
      this.postData.schedule = []
      this.loading = true
      for (const hour in this.hours) {
        // console.log(hour, this.hours[hour])
        if (this.hours[hour] !== null) {
          this.postData.schedule.push({
            day: this.weekday,
            hour: hour,
            discount: this.hours[hour],
            people: parseInt(this.postData.maxPeople)
          })
        }
      }
      console.log(this.postData)
      if (this.submitType === 'create') {
        try {
          this.postData.maxPeople = parseInt(this.postData.maxPeople)
          var postResp = await _discountCalendarService.create(this.postData)
          console.log(postResp)
          this.initialize()
          this.clearForm()
          this.loading = false
          this.$notify({
            type: 'success',
            text: 'Se guardo el evento correctamente'
          })
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$notify({
            type: 'error',
            text: 'Ocurrió un error al guardar el evento'
          })
        }
      } else {
        console.log('edit path')
        try {
          this.postData.maxPeople = parseInt(this.postData.maxPeople)
          console.log(this.postData, this.eventId)
          var postEditResp = await _discountCalendarService.edit(this.eventId, this.postData)
          console.log(postEditResp)
          this.initialize()
          this.clearForm()
          this.loading = false
          this.$notify({
            type: 'success',
            text: 'Se guardo el evento correctamente'
          })
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$notify({
            type: 'error',
            text: 'Ocurrió un error al guardar el evento'
          })
        }
      }
    },
    async deleteEvent (id) {
      this.$swal({
        title: '¿Está seguro de eliminar el evento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await _discountCalendarService.destroy(id)
            this.$swal(
              'Producto eliminado',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo eliminar.'
            })
          }
        }
      })
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
      // this.$router.push({ name: 'companies' })
    }
  }
}
